<template>
   <div class="container orderList">
      <table class="stockList">
            <thead>
               <tr>
                  <td>序号</td>
                  <td class="largeColumn">订单ID</td>
                  <td>股票名称</td>
                  <td>最新价格</td>
                  <td>成交价格</td>
                  <td>时间</td>
                  <td>成交数量(股数)</td>
                  <td>类型</td>
                  <td class="largeColumn">订单发起原因</td>
                  <td>状态</td>
               </tr>
            </thead>
            <tbody>
               <tr v-for="(item,index) in dataList" :key="index" :style="{order:parseInt(item.requestTime)}">
                  <td>{{index+1}}</td>
                  <td class="largeColumn">{{item.orderId}}</td>
                  <td>
                     <div>{{item.name}}</div>
                     <div style="color:gray;font-size:14px;">{{item.code}}</div>
                  </td>
                  <td>{{item.last_price}}</td>
                  <td>
                     <div>
                        <span class="des">请求</span>
                        <span class="name">{{item.requestPrice}}</span>
                     </div>
                     <div>
                        <span class="des">成交</span>
                        <span class="name">{{item.transactionPrice}}</span>
                     </div>
                  </td>
                  <td>
                     <div>
                        <span class="des">请求</span>
                        <span class="name">{{item.requestTime | timestampFormatToTime}}</span>
                     </div>
                     <div>
                        <span class="des">成交</span>
                        <span class="name">{{item.transactionTime | timestampFormatToTime}}</span>
                     </div>
                  </td>
                  <td>{{item.number}}</td>

                  <td>
                     <span v-if='item.type == "Buy"'>买入</span>
                     <span v-if='item.type == "Sell"'>卖出</span>
                  </td>

                  <td class="largeColumn">{{item.reasonDes}}</td>

                  <td>
                     <!-- Ongoing(正在进行)，Undoing(正在撤消)，completed(已完成)，Revoked(已撤消)，Failed(已失败) -->
                     <span v-if='item.operation == "Ongoing"'>正在进行</span>
                     <span v-if='item.operation == "Undoing"'>正在撤消</span>
                     <span v-if='item.operation == "Completed"'>已完成</span>
                     <span v-if='item.operation == "Revoked"'>已撤消</span>
                     <span v-if='item.operation == "Failed"'>失败</span>
                  </td>

               </tr>
            </tbody>
      </table>
   </div>
</template>

<script>
export default {
   data(){
      return {
         dataList:[],

         webSocket:null,           // websocket对象
         webSocketStatus:"未连接", // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){
      this.webSocketConnect();
   },
   methods:{

      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151")
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
            that.webSocket.send(JSON.stringify({
               operationType:'orderList'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               let array = JSON.parse(evt.data);
               that.dataList = array.sort(function (a, b) {
                  return (b.requestTime - a.requestTime);
               });
            } catch(e) {
               console.log(evt.data);
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;
   }
}
</script>

<style scoped>

.container{
   display:flex;
   flex-direction:column;
}

table.stockList{
   width: 100%;
   height: 100%;
}
table.stockList tbody {
   display:block;
   overflow:auto;
   height: calc(100% - 26px);
}
table.stockList thead tr{
   font-weight: bold;
}
table.stockList thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed; /* even columns width , fix width of table too*/
}
table.stockList tbody tr:hover{
   background-color: rgb(245, 245, 245);
}
table.stockList tr>td .name{
   display: inline-block;
}
table.stockList tr>td .des{
   color:rgb(160, 160, 160);
   margin-right: 5px;
   font-size: 15px;
}
table.stockList tr>td:nth-child(1){
   width:60px;
   text-align:center;
}
table.stockList tr>td.largeColumn{
   width:180px;
}



</style>